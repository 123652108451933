$(document).ready(function () {
    //Deshabilitar autocomplete del login
    $('form[name="login"]').attr("autocomplete", "off");
    //Inicializar placeholder
    $('input, textarea').placeholder();
    //Control del login
    $("#closeUserError").click(function () {
        $("#userError").hide();
    });
    $("#closePassError").click(function () {
        $("#passwordError").hide();
    });
    $("#_submit").on("click", function (event) {
        var url = window.urlLoginCheck; // the script where you handle the form input.

        $.ajax({
            type: "POST",
            url: url,
            data: $("#login").serialize(), // serializes the form's elements.
            success: function (data) {
                if (data == "101") {
                    $("#userError").show();
                    $("#passwordError").hide();
                }
                else if (data == "102") {
                    $("#userError").hide();
                    $("#passwordError").show();
                }
                else if (data == "200") {
                    $("#userError").hide();
                    $("#passwordError").hide();
                    $("#login").submit();
                }

            },
            timeout: 60000
        });
        event.preventDefault();
        // avoid to execute the actual submit of the form.
    });

    //evitar autofill de los inputs de acceso
    if (navigator.userAgent.toLowerCase().indexOf("chrome") >= 0) {
        $(window).load(function(){
            $('input:-webkit-autofill').each(function(){
                var text = $(this).val();
                var name = $(this).attr('name');
                $(this).after(this.outerHTML).remove();
                $('input[name=' + name + ']').val(text);
            });
        });
    }

    //mostrar y ocultar elementos en la barra de menu principal
    $('.input-navbar').prop('autocomplete',false);
    $('#acceder').click(function() {
        //e.stopPropagation();
        $('.dropdown-menu').slideUp(10, function() {
            $(".nav-complete").hide("slow", function() {
                $(".nav-acceder").show("slow");
            });
        });

        /*$(".nav-buscar").hide("slow");*/

    });
    $('.registrate').click(function(e) {
        e.stopPropagation();
        window.location.href = $(this).attr('href');
        $('.dropdown-menu').slideUp(10, function() {
            $(".nav-complete").hide("slow", function() {
            });
        });
    });

    $('.lets-close').click(function() {
        $(".nav-complete").show("slow");
        $(".nav-acceder").hide("slow");
        /*$(".nav-buscar").hide("slow");*/
    });

    //activar tooltips y popovers
    $('[data-toggle=tooltip]').tooltip().click(function(e) {
        $(this).tooltip('toggle');
    });
    $('[data-toggle=popover]').popover().click(function(e) {
        $(this).popover('toggle');
    });


    //imagenes aleatorias en fondos
    if ($('#left1').length && $('.fondoconfia').length === 0){
        //amarillo+verde
        $('.fondoprincipal').css({'background-image': 'url(/img/fondos/fondo-zank-AMVE-' + (Math.floor(Math.random() * 7) + 1)  + '.jpg)'});
    } else if ($('#left2').length && $('.fondoconfia').length === 0) {
        //azul
        $('.fondoprincipal').css({'background-image': 'url(/img/fondos/fondo-zank-AZAZ-' + (Math.floor(Math.random() * 7) + 1)  + '.jpg)'});
    } else if ($('#left3').length && $('.fondoconfia').length === 0) {
        //amarillo
        $('.fondoprincipal').css({'background-image': 'url(/img/fondos/fondo-zank-AMAM-' + (Math.floor(Math.random() * 7) + 1)  + '.jpg)'});
    } else if ($('#left4').length && $('.fondoconfia').length === 0) {
        //verde
        $('.fondoprincipal').css({'background-image': 'url(/img/fondos/fondo-zank-VEVE-' + (Math.floor(Math.random() * 7) + 1)  + '.jpg)'});
    } else if ($('#left5').length) {
        //blanco
        $('#left5').remove();
        $('#right5').remove();
        $('#footer').remove();
        $('body').css({'background-color': '#ffffff'});
        $('html').css({'background-color': '#ffffff'});
        $('.navbar-main').css({'background-color': 'rgba(165, 182, 78, 1)'});
    }

    // toda la configuración siguiente hace conflicto con el navbar collapse de bootstrap

    $('.dropdown.hidden-lg.hidden-md.hidden-sm').on('click',function(e){
        if (!$(this).hasClass('open')) {
            $('.dropdown.hidden-lg.hidden-md.hidden-sm').removeClass('activo');
            $(this).addClass('activo');
            $('.dropdown.hidden-lg.hidden-md.hidden-sm:not(.activo)').hide();
            $('#atras').toggle(300);

            $(this).find('ul.dropdown-menu').slideDown();
        }
        else {
            e.stopPropagation();
            $(this).find('ul.dropdown-menu').slideUp(function() {
                $('.dropdown.hidden-lg.hidden-md.hidden-sm.open').removeClass('open');
            });
        }
    });

    $('#atras').on('click',function(e){
        e.stopPropagation();
        $('.dropdown.hidden-lg.hidden-md.hidden-sm.activo ul.dropdown-menu').slideUp(function() {
            $('.dropdown.hidden-lg.hidden-md.hidden-sm.open').removeClass('open');
            $('.dropdown.hidden-lg.hidden-md.hidden-sm').removeClass('activo').show();
            $('#atras').toggle(300);
        });
    });

    $('.navbar-header button').click(function(e) {
        e.stopPropagation();
        if ($('#navbar-contenido-collapse').hasClass('in')) {
            $('#navbar-contenido-collapse').slideUp(300, function(e) {
                $('#navbar-contenido-collapse').removeClass('in');
            });
        }
        else {
            $('#navbar-contenido-collapse').slideDown(300, function() {
                $('#navbar-contenido-collapse').addClass('in');
            });
        }
    });

    $("#search-multi").smartSuggest({
        src: window.smartSearch,
        timeoutLength: 1000,
        minChars: 3
    });
});
